import React, { useEffect } from 'react';
import styles from '../styles/Modal.module.scss';
import closeBtn from '../assets/images/close-btn.svg';

const Modal = ({
  type,
  title,
  subtitle,
  body,
  modalListItems,
  image,
  showModal,
  setShowModal,
  url
}) => {
  useEffect(() => {
    if (showModal) {
      document.body.classList.add('overflow-hidden');

      let windowUrl = window.location.toString();

      windowUrl = windowUrl.split('#')[0];

      if (!windowUrl.includes('?')) {
        windowUrl += `${
          type === 'investment'
            ? `?investment=${url}`
            : type === 'profile'
            ? `?profile=${url}`
            : ''
        }`;

        const nextTitle = 'MSD GHI Fund';
        const nextState = { additionalInformation: 'Updated the URL with JS' };
        window.history.replaceState(nextState, nextTitle, windowUrl);
      }
    }
  }, [showModal, type, url]);

  const resetUrl = () => {
    const nextURL = '/';
    const nextTitle = 'MSD GHI Fund';
    const nextState = {
      additionalInformation: 'Updated the URL with JS'
    };
    window.history.replaceState(nextState, nextTitle, nextURL);
    document.body.classList.remove('overflow-hidden');
  };

  return (
    <div className={`${styles.modalWrapper} ${showModal && styles.show}`}>
      <div className={`${styles.modal} ${showModal && styles.show}`}>
        <img className={styles.mobileImage} src={image} alt={title} />
        <div className={styles.modalContent}>
          <img
            src={closeBtn}
            className={styles.modalClose}
            onClick={() => {
              setShowModal(false);
              resetUrl();
            }}
            alt='Close button'
          />
          <h2>{title}</h2>
          <p className={styles.modalTitle}>{subtitle}</p>
          <p className={styles.modalBody}>{body}</p>
          {type === 'investment' &&
            modalListItems?.map((item) => (
              <p className={styles.modalListItem}>{item}</p>
            ))}
        </div>
        <img src={image} className={styles.modalImage} alt={title} />
      </div>
    </div>
  );
};

export default Modal;
