import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import alumni from '../data/alumni';
import styles from '../styles/Alumni.module.scss';

const Alumni = () => {
  return (
    <Container className={styles.alumniWrapper}>
      <Row>
        <h2>Portfolio Alumni</h2>
      </Row>
      <Row className={styles.alumniGrid}>
        {alumni.map((item) => {
          return (
            <Col lg={2} md={3} sm={6} xs={12}>
              <img src={item.image} alt={`${item.name} logo`} />
              <p>{item.text}</p>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default Alumni;
