import abSciLogo from "../assets/images/logos/logo_absci.jpg";
import aeroSafeLogo from "../assets/images/logos/logo_aerosafe.jpg";
import antidoteLogo from "../assets/images/logos/logo_antidote.jpg";
import arcadiaLogo from "../assets/images/logos/logo_arcadia.jpg";
import asterInsightsLogo from "../assets/images/logos/logo_aster_insights.jpg";
import aitiaLogo from "../assets/images/logos/logo_aitia.png";
import baseImmuneLogo from "../assets/images/logos/logo_baseimmune.jpg";
import cancerIqLogo from "../assets/images/logos/logo_canceriq.png";
import clearDataLogo from "../assets/images/logos/logo_cleardata.jpg";
import cliniThinkLogo from "../assets/images/logos/logo_clinithink.jpg";
import culminationLogo from "../assets/images/logos/logo_culmination.jpg";
import datavantLogo from "../assets/images/logos/logo_datavant.png";
import evaxionLogo from "../assets/images/logos/logo_evaxion.jpg";
import infectiousDiseaseLogo from "../assets/images/logos/logo_infectious_disease.jpg";
import koneksaLogo from "../assets/images/logos/logo_koneksa.jpg";
import mediFundLogo from "../assets/images/logos/logo_medifund.jpg";
import navigatingCancerLogo from "../assets/images/logos/logo_navigatingcancer.jpg";
import octaveLogo from "../assets/images/logos/logo_octave.jpg";
import pathAiLogo from "../assets/images/logos/logo_pathai.jpg";
import pragmaBioLogo from "../assets/images/logos/logo_pragmaBio.jpg";
import preciseDxLogo from "../assets/images/logos/logo_precisedx.jpg";
import prognosLogo from "../assets/images/logos/logo_prognos.jpg";
import rxVantageLogo from "../assets/images/logos/logo_rxvantage.jpg";
import saamaLogo from "../assets/images/logos/logo_saama.jpg";
import strataLogo from "../assets/images/logos/logo_strata.jpg";
import syapseLogo from "../assets/images/logos/logo_syapse.jpg";
import tagNTracLogo from "../assets/images/logos/logo_tagNTrac.jpg";
import tassoLogo from "../assets/images/logos/logo_tasso.jpg";
import transcarentLogo from "../assets/images/logos/logo_transcarent.jpg";
import transvoyantLogo from "../assets/images/logos/logo_transvoyant.jpg";
import triNetXLogo from "../assets/images/logos/logo_trinetx.jpg";
import turbineLogo from "../assets/images/logos/logo_turbine.png";
import uniteUsLogo from "../assets/images/logos/logo_uniteus.jpg";
import unnaturalProductsLogo from "../assets/images/logos/logo_unnaturalproducts.jpg";
import veranaHealthLogo from "../assets/images/logos/logo_veranahealth.jpg";
import vergeGenomicsLogo from "../assets/images/logos/logo_vergegenomics.jpg";
import wellDocLogo from "../assets/images/logos/logo_welldoc.jpg";
import zapataLogo from "../assets/images/logos/logo_zapata.jpg";
import ashWellnessLogo from "../assets/images/logos/logo_ashwellness.jpg";
import artoposLogo from "../assets/images/logos/logo_atropos.jpg";
import cargosenseLogo from "../assets/images/logos/logo_cargosense.jpg";
import delfiLogo from "../assets/images/logos/logo_delfi.jpg";
import qureaiLogo from "../assets/images/logos/logo_qureai.jpg";


const portfolio = [
  {
    name: "AbSci",
    description: "AbSci is a pioneering synthetic biology company that unifies biologic drug discovery and development processes.",
    link: "https://www.absci.com/",
    image: abSciLogo
  },
  {
    name: "AeroSafe Global",
    description:
      "Delivers cold-chain services for the bio-pharma industry that result in zero temperature excursions for every client,every product, every time.",
    link: "http://www.aerosafeglobal.com/",
    image: aeroSafeLogo
  },
  {
    name: "Aitia",
    description: "Aitia is the leading causal AI company enabling the discovery of which patients respond to drugs and why.",
    link: "https://www.aitiabio.com/",
    image: aitiaLogo
  },
  {
    name: "Antidote",
    description: "Antidote has built a clinical trial matching platform that helps patients easily find studies right for them.",
    link: "https://www.antidote.me/",
    image: antidoteLogo
  },
  {
    name: "Arcadia",
    description:
      "Arcadia Healthcare Solutions is a population health and analytics technology company supporting ambulatory networks taking on value-based risk and transitioning to managed care.",
    link: "http://www.arcadiasolutions.com/",
    image: arcadiaLogo
  },
  {
    name: "Ash Wellness",
    description: "End-to-end solution that allows organizations to offer at-home diagnostic testing to their customers and patients.",
    link: "https://www.poweredbyash.com/",
    image: ashWellnessLogo
  },
  {
    name: "Aster Insights",
    description: "A collaborative network using harmonized real world oncology discovery data.",
    link: "https://www.asterinsights.com/",
    image: asterInsightsLogo
  },
  {
    name: "Atropos Health",
    description: "Provides on-demand evidence from real world data to close evidence gaps in healthcare and life sciences.",
    link: "https://www.atroposhealth.com/",
    image: artoposLogo
  },
  {
    name: "Baseimmune",
    description: "Combining scientific expertise with our proprietary AI-platform to generate mutation-resistant vaccines.",
    link: "https://www.baseimmune.co.uk/",
    image: baseImmuneLogo
  },
  {
    name: "CancerIQ",
    description: "Genetic cancer risk assessment.",
    link: "https://www.canceriq.com/",
    image: cancerIqLogo
  },
  {
    name: "CargoSense",
    description: "Supporting operational continuity through supply chain visibility.",
    link: "https://www.cargosense.com/",
    image: cargosenseLogo
  },
  {
    name: "ClearData",
    description:
      "Provide healthcare managed cloud and security platform integrated with AWS; healthcare exclusive, HIPAA-compliant, HITRUST CSF Certified.",
    link: "https://www.cleardata.com/",
    image: clearDataLogo
  },
  {
    name: "CliniThink",
    description:
      "Clinithink’s platform delivers the fastest and most accurate analysis of unstructured data across patient populations, helping life sciences companies get treatments to market faster.",
    link: "https://clinithink.com/",
    image: cliniThinkLogo
  },
  {
    name: "Culmination",
    description: "Developing novel clinical insights, therapeutic targets, diagnostic opportunities, and care process models.",
    link: "https://www.culmination.com/",
    image: culminationLogo
  },
  {
    name: "Datavant",
    description: "Connecting health care data.",
    link: "https://datavant.com/",
    image: datavantLogo
  },
  {
    name: "Delfi Diagnostics",
    description: "DELFI uses novel machine learning algorithms to analyze fragments of cell-free DNA.",
    link: "https://delfidiagnostics.com/",
    image: delfiLogo
  },
  {
    name: "Evaxion",
    description: "Saving lives with AI-Immunology™ powered vaccines.",
    link: "https://www.evaxion-biotech.com/",
    image: evaxionLogo
  },
  {
    name: "Infectious Disease Connect",
    description:
      "Brings a team of world-class ID Specialists to hospitals in a cost-effective manner, providing primary expertise, back-fill support, or staff augmentation.",
    link: "http://www.idctelemed.com/",
    image: infectiousDiseaseLogo
  },
  {
    name: "Koneska",
    description: "Consistently and objectively captures patient reported outcomes and device data in clinical research.",
    link: "https://www.koneksahealth.com/",
    image: koneksaLogo
  },
  {
    name: "NavigatingCancer",
    description: "Patient relationship management platform to facilitate coordinated, connected and comprehensive cancer care.",
    link: "http://www.navigatingcancer.com/provider/",
    image: navigatingCancerLogo
  },
  {
    name: "Octave",
    description:
      "Octave is providing the first blood test to accurately measure disease activity at the biology level to personalize patient decision making.",
    link: "https://www.octavebio.com/",
    image: octaveLogo
  },
  {
    name: "PathAI",
    description:
      "PathAI is developing technology that assists pathologists in making rapid and accurate diagnoses for every patient, every time. Our technology promises to better diagnose—and treat—some of today's most challenging diseases.",
    link: "https://www.pathai.com/",
    image: pathAiLogo
  },
  {
    name: "Pragma Bio",
    description:
      "Leveraging advanced technologies in machine learning and synthetic biology to scale intelligent discovery with iterative biomanufacturing.",
    link: "http://www.pragmabio.com/",
    image: pragmaBioLogo
  },
  {
    name: "PreciseDx",
    description:
      "PreciseDx provides artificial intelligence guided pathology diagnostics to improve patient outcomes in the field of cancer and other diseases.",
    link: "https://precisedx.ai/",
    image: preciseDxLogo
  },
  {
    name: "Prognos",
    description: "Leveraging clinical data, analytics and artificial intelligence to drive the right decisions earlier to improve health.",
    link: "http://www.prognos.ai/",
    image: prognosLogo
  },
  {
    name: "Qure AI",
    description:"AI powered reporting and seamless collaboration to make healthcare more accessible and equitable.",
    link: "https://www.qure.ai/",
    image: qureaiLogo
  },
  {
    name: "RxVantage",
    description:
      "Bringing education resources that life science companies create into an easy-to-use app that healthcare providers use to get the answers they need for patients.",
    link: "http://www.rxvantage.com/",
    image: rxVantageLogo
  },
  {
    name: "Saama",
    description:
      "AI clinical analytics platform company, enabling the life sciences industry to conduct faster and safer clinical development and regulatory programs.",
    link: "https://www.saama.com/",
    image: saamaLogo
  },
  {
    name: "Strata Oncology",
    description: "Strata Oncology is delivering a new way forward for precision oncology that is highly-scaled, systematic and data-driven.",
    link: "https://www.strataoncology.com/",
    image: strataLogo
  },
  {
    name: "Syapse",
    description:
      "Syapse drives healthcare transformation through precision medicine, enabling provider systems to improve clinical outcomes, streamline operations, and shift to new payment models.",
    link: "http://syapse.com/",
    image: syapseLogo
  },
  {
    name: "Tag-N-Trac",
    description:
      "Provides a full stack IoT Platform as a Service (PaaS) startup offering turnkey solutions for Serialization, Continuous Inventory, Logistics, and Anomoly traceability.",
    link: "https://www.tagntrac.com/",
    image: tagNTracLogo
  },
  {
    name: "Tasso",
    description: "Tasso offers a reliable and scalable solution to continue blood testing without a face-to-face phlebotomy draw.",
    link: "http://www.tassoinc.com/",
    image: tassoLogo
  },
  {
    name: "Transcarent",
    description: "Transcarent is a consumer-directed health and care platform for employees of self-insured employers.",
    link: "https://transcarent.ai/",
    image: transcarentLogo
  },
  {
    name: "Transvoyant",
    description: "The leader in real-time actionable supply chain intelligence.",
    link: "https://transvoyant.com/",
    image: transvoyantLogo
  },
  {
    name: "TriNetX",
    description:
      "TriNetX is the global health research network that optimizes clinical research and enables discoveries through the creation of real-world evidence.",
    link: "https://www.trinetx.com/",
    image: triNetXLogo
  },
  {
    name: "Turbine",
    description: "In silico disease modeling.",
    link: "https://turbine.ai/",
    image: turbineLogo
  },
  {
    name: "Unite Us",
    description: "Unite Us is an outcome-focused technology company that builds coordinated care networks fo health and social service providers",
    link: "https://uniteus.com/",
    image: uniteUsLogo
  },
  {
    name: "Unnatural Products",
    description:
      "Unnactural Products' AI-enhanced drug discovery and development technology engineers entirely new drugs from passively permeable, macrocylic peptides.",
    link: "https://www.unnaturalproducts.com/",
    image: unnaturalProductsLogo
  },
  {
    name: "Verana Health",
    description: "Verana Health is assembling the largest clinical databases in medicine to empower physicians and accelerate research for patients.",
    link: "https://www.veranahealth.com/",
    image: veranaHealthLogo
  },
  {
    name: "Verge Genomics",
    description: "Next-generation meidicines from human genomics.",
    link: "https://www.vergegenomics.com/",
    image: vergeGenomicsLogo
  },
  {
    name: "WellDoc",
    description:
      "The only FDA cleared healthcare intervention solution that has proven to reduce A1C levels for patients living with type 2 diabetes.",
    link: "https://www.welldoc.com/",
    image: wellDocLogo
  },
  {
    name: "Zapata",
    description:
      "Translating business problems to work on near-term quantum computers for chemistry, finance, logistics, pharmaceuticals, engineering, and materials.",
    link: "http://www.zapatacomputing.com/",
    image: zapataLogo
  }
];

export default portfolio;
