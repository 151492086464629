import tarantoImg from "../assets/images/people/taranto.jpg";
import pohlImg from "../assets/images/people/pohl.jpg";
import stevensonImg from "../assets/images/people/stevenson.jpg";
import rubinImg from "../assets/images/people/rubin.jpg";
import liImg from "../assets/images/people/li.jpg";
import volpeImg from "../assets/images/people/volpe.jpg";
import krikstonImg from "../assets/images/people/krikston.jpg";
import morganImg from "../assets/images/people/morgan.jpg";
import tumkositImg from "../assets/images/people/tumkosit.jpg";
import coreyImg from "../assets/images/people/corey.jpg";
import susieImg from "../assets/images/people/susie.jpg";

const people = [
  {
    id: 0,
    name: "Bill Taranto",
    title: "President & General Partner",
    image: tarantoImg,
    url: "bill-taranto",
    body: "Bill Taranto is President of the GHI Fund and founding partner since inception in 2010. Bill has more than 30 years of health care experience including over 20 years of healthcare investing. In addition to his experience in venture investing, Bill has more than a decade of management operations experience. Today Bill is the chairman of the MSD Innovative Ventures Board, a group that manages the identification and funding of portfolio businesses; and is also Chairman of the GHI Fund LLC Management Board. Prior to joining MSD, Bill spent 18 years at Johnson & Johnson in various venture capital, marketing, sales and business development roles. Before J&J, Bill spent eight years in investment banking. He has a Bachelor of Business Administration from St. Bonaventure University."
  },
  {
    id: 1,
    name: "Joel Krikston",
    title: "Managing Director",
    image: krikstonImg,
    url: "joel-krikston",
    body: "Joel Krikston is Managing Director Venture Investments and Head of Strategic Innovation Alliances. Joel is focused on identifying new investment opportunities with a focus on Oncology, Vaccines and Infectious Disease. Mr. Krikston has over 20 years of experience in healthcare innovation. Prior to joining MSD GHI in 2013, he was an Investment Principal at Johnson & Johnson Development Corporation. Joel also spent over eight years as an investment banker, covering an array of healthcare clients and included experience at BNP Paribas, JPMorgan and ABN Amro. Joel has an MBA from the Stern School of Business at NYU and completed his Bachelor’s degree in Finance and International Business at Fairfield University."
  },
  {
    id: 2,
    name: "Hanqing Li",
    title: "Investment Director",
    image: liImg,
    url: "hanqing-li",
    body: "Hanqing is an Investment Director for MSD GHI Fund and is responsible for supporting all areas of digital health. Prior to joining MSD, Hanqing was a technical specialist at Finnegan, Henderson, Farabow, Garrett & Dunner, where her responsibilities included conducting due diligence and freedom to operate analyses in the life sciences industry; drafting patent applications; patent portfolio management; and patent prosecution in jurisdictions around the world.  Hanqing also worked in R&D for Quantgene, a start-up focused on the detection of early-stage cancers using liquid biopsies.  Hanqing holds a Ph.D. in Biology from the California Institute of Technology, where  her research focused on the development of assays for detecting protein-protein interactions, as well as their utilization in the study of the neuromuscular junction in D. melanogaster models."
  },
  {
    id: 3,
    name: "Mike Morgan",
    title: "Principal",
    image: morganImg,
    url: "mike-morgan",
    body: "Mike is a Principal for MSD GHI Fund and is responsible for supporting all areas of digital health. Prior to joining MSD in 2021, Mike was a Senior Manager in the Life Sciences Strategy Practice at Accenture. His experience includes venture capital, M&A due-diligence, business case development, real-world evidence, and growth strategy. Mike holds an MBA from Yale School of Management and a BA from Haverford College."
  },
  {
    id: 4,
    name: "Susie Oblak",
    title: "Executive Director, Strategic Innovation Alliances",
    image: susieImg,
    url: "susie-oblak",
    body: "Susie Oblak is an Executive Director with MSD GHI Fund leading Strategic Innovation Alliances. Susie has 20+ years of experience in healthcare strategy, growth, and innovation. Prior to joining MSD GHI in 2024, she was SVP, Technology Partnerships for Komodo Health. Susie has also served as a consultant with MSD’s Global Partnerships & Innovation team, UK General Manager for Big Health, and in senior commercial roles for the Advisory Board in London, UK and Washington, DC. Susie has an MBA from INSEAD in France/ Singapore and completed her BS at the University of Virginia’s McIntire School of Commerce."
  },
  {
    id: 5,
    name: "David Rubin",
    title: "Managing Director",
    image: rubinImg,
    url: "david-rubin",
    body: "David M. Rubin, Ph.D., is a Managing Director and a founding partner of GHI Fund since inception in 2010. David is focused on digital health and precision medicine mainly focused in oncology. David has spent more than 30 years in the healthcare industry. Before joining the GHI team, Dr. Rubin led a portfolio management group in MSD Research Labs, where he facilitated the development of franchise strategies and analysis of portfolio valuations. David joined MSD from Cognia Corporation, a venture-backed international healthcare R&D software company, where he was CEO. Previously, Dr. Rubin was a management consultant at The Wilkerson Group/IBM Global Services. Dr. Rubin holds a Ph.D. from Temple University in Molecular Biology and a B.A. from SUNY Binghamton in Biology. He was a National Institutes of Health and American Cancer Society Post-Doctoral fellow at Harvard Medical School where he worked on the Ubiquitin Proteasome Pathway. He also received training in post-graduate business at Harvard University."
  },
  {
    id: 6,
    name: "Dave Stevenson",
    title: "Managing Director",
    image: stevensonImg,
    url: "dave-stevenson",
    body: "Dave Stevenson is COO & Managing Director and a founding partner of GHI Fund since inception in 2010. Dave is responsible for identifying opportunities broadly across digital health and also manages the GHI portfolio and operations. Dave brings more than 25 years of healthcare strategy and operations expertise to the GHI team. Prior to MSD GHI, Dave was Head of U.S. Strategy at Sanofi responsible for development of the company’s new commercial models. Dave started his career with Novartis where he built their first clinical trial management system. He holds a MBA from Vanderbilt University and a B.A. from Washington University."
  },
  {
    id: 7,
    name: "Joe Volpe",
    title: "Managing Director",
    image: volpeImg,
    url: "joe-volpe",
    body: "Joe Volpe is a Managing Director and a founding partner of GHI Fund since inception in 2010. Joe focuses on Health IT, Manufacturing/Supply Chain and healthcare evidence-based data and systems digital platforms. He has more than 30 years of experience in health care investing applying a technical engineering background coupled with an understanding of emerging technologies in health care. Joe came to MSD from Johnson and Johnson where he spent more than 20 years in innovation and investment roles across Consumer, Pharma and Device sectors. Prior to that he was a consultant in Health Care, Military and Robotics Systems with EDS where he was selected and graduated from their Top Gun business finance and technology programs. He went to the University of South Florida where he obtained a B.S. in Business/Information Systems and Engineering."
  }
];

export default people;
