import React from 'react';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import InvestmentTile from '../components/InvestmentTile';
import styles from '../styles/SectionThree.module.scss';
import investmentData from '../data/investments';

const InvestmentFocus = ({ currentQuery }) => {
  return (
    <Container className={styles.sectionThreeWrapper}>
      <h2 className={styles.investmentsHeader}>Our Investment Focus Areas</h2>
      <Row className={styles.investmentsWrapper}>
        {investmentData.map((investment) => (
          <InvestmentTile investment={investment} currentQuery={currentQuery} />
        ))}
      </Row>
    </Container>
  );
};

export default InvestmentFocus;
