import Intro from '../sections/Intro';
import Leadership from '../sections/Leadership';
import InvestmentFocus from '../sections/InvestmentFocus';
import Portfolio from '../sections/Portfolio';
import GhifDistinction from '../sections/GhifDistinction';
import OurTeam from '../sections/OurTeam';
import BackToTop from '../components/BackToTop';
import useOnScreen from '../utils/useOnScreen';

export const Main = ({ profile, investment, introRef, footerRef }) => {
  const introShowing = useOnScreen(introRef);
  const footerShowing = useOnScreen(footerRef);

  return (
    <>
      <Intro introRef={introRef} />
      <Leadership />
      <InvestmentFocus currentQuery={investment ? investment : null} />
      <Portfolio />
      <GhifDistinction />
      <OurTeam currentQuery={profile ? profile : null} />
      <BackToTop show={!introShowing && !footerShowing} />
    </>
  );
};

export default Main;
