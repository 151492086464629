import { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useRef } from 'react';
import useOnScreen from './utils/useOnScreen';
import Navigation from './components/Navigation';
import Main from './pages/Main';
import Alumni from './pages/Alumni';
import Footer from './components/Footer';

const App = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const profile = queryParams.get('profile');
  const investment = queryParams.get('investment');

  const introRef = useRef(null);
  const footerRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      const id = window.location.hash.slice(1); // remove leading '#'
      const el = document.getElementById(id);
      if (el) {
        el.scrollIntoView();
      }
    }, 200);
  });

  return (
    <div className='App'>
      <Navigation />
      <BrowserRouter>
        <Routes>
          <Route
            path='/'
            index
            element={
              <Main
                profile={profile}
                investment={investment}
                introRef={introRef}
                footerRef={footerRef}
              />
            }
          />
          <Route path='/alumni' element={<Alumni />} />
        </Routes>
      </BrowserRouter>
      <Footer footerRef={footerRef} />
    </div>
  );
};

export default App;
