import backToTopButton from '../assets/images/icons/back-to-top.svg';
import styles from '../styles/BackToTop.module.scss';

const BackToTop = ({ show }) => {
  const scrollTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  console.log(show);
  return (
    <img
      src={backToTopButton}
      className={`${styles.backToTop} ${show && styles.show}`}
      onClick={scrollTop}
    />
  );
};

export default BackToTop;
