import ddcdImage from '../assets/images/ddcd.jpg';
import supplyImage from '../assets/images/supply.jpg';
import supportImage from '../assets/images/support.jpg';
import evidenceImage from '../assets/images/evidence.jpg';
import ddcdModalImage from '../assets/images/ddcd-large.jpg';
import supplyModalImage from '../assets/images/supply-large.jpg';
import supportModalImage from '../assets/images/support-large.jpg';
import evidenceModalImage from '../assets/images/evidence-large.jpg';

const investments = [
  {
    caption: 'Drug Discovery & Clinical Development',
    secondary:
      'Opportunities to enhance drug discovery and accelerate clinical development.',
    image: ddcdImage,
    modalBody:
      'Opportunities to accelerate and improve the discovery of novel medicines, as well as to accelerate clinical development.',
    modalListItems: [
      'Digital data networks & analytics for target identification​',
      'NCE/NME drug discovery platform',
      'Novel delivery platforms',
      'Synthetic biology',
      'Molecular diagnostics',
      'Digital pathology & radiology',
      'Digital biomarkers & remote monitoring',
      'Blood-based diagnostics & sampling',
      'eClinical, patient recruitment & data management platforms'
    ],
    modalImage: ddcdModalImage,
    url: 'drug-discovery-clinical-development'
  },
  {
    caption: 'Manufacturing, Supply Chain, & IT',
    secondary:
      'Enhancing efficiency and reliability of supply chain, manufacturing, IT and enterprise operations.',
    image: supplyImage,
    modalBody:
      'Enhancing speed, nimbleness, and reliability of the supply chain, manufacturing, IT and enterprise operations.',
    modalListItems: [
      'Digital supply chain',
      'Plant-to-patient',
      'Connected floors & labs',
      'Data and application integration',
      'Enterprise privacy and security',
      'Enterprise architecture',
      'Quantum computing',
      'Blockchain'
    ],
    modalImage: supplyModalImage,
    url: 'manufacturing-supply-chain-it'
  },
  {
    caption: 'Access to Medicine & Patient Support',
    secondary:
      'Improving access to the right treatment for the right patient, and getting patients to outcome.',
    image: supportImage,
    modalBody:
      'Improving access to treatment, ensuring delivery of the right treatment to the right patient at the right time, and getting patients to outcome.',
    modalListItems: [
      'Social determinants of health',
      'Patient centric care',
      'Commerical data analytics & market support',
      'Clinical decision support and evidence-based medicine',
      'Provider communications',
      'Remote monitoring',
      'Patient support & care management'
    ],
    modalImage: supportModalImage,
    url: 'access-to-medicine-patient-support'
  },
  {
    caption: 'Real World Evidence Generation',
    secondary:
      'Enabling transparency in outcomes through generation of real world evidence and insights.',
    image: evidenceImage,
    modalBody:
      'Enabling transparency in outcomes through generation of real world evidence and insights.',
    modalListItems: [
      'Data & analytics to power real world evidence',
      'Scaled data networks',
      'Multimodal longitudinal data',
      'Identifying care gaps',
      'Population health screening',
      'Access to clinical trials'
    ],
    modalImage: evidenceModalImage,
    url: 'real-world-evidence-generation'
  }
];

export default investments;
