import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Accordion from 'react-bootstrap/Accordion';
import distinctions from '../data/ghif-distinction';
import styles from '../styles/SectionFour.module.scss';
import rightButton from '../assets/images/icons/right-button.svg';

const GhifDistinction = () => {
  const [currentDistinction, setCurrentDistinction] = useState(distinctions[0]);

  return (
    <Container className={styles.sectionFourWrapper} id='ghif-distinction'>
      <Row className={styles.distinctionSelectorWrapper}>
        <div className={styles.distinctionSelector}>
          <div className={styles.distinctionSelectorLeft}>
            <h2 className='ghi'>The GHIF Distinction</h2>
            {distinctions.map((distinction) => (
              <div
                role='button'
                className={`${styles.distinction} ${
                  distinction === currentDistinction && styles.active
                }`}
                onClick={() =>
                  setCurrentDistinction(distinctions[distinction.id])
                }
                key={distinction.id}
              >
                <p>{distinction.name}</p>
                <img src={rightButton} alt='' />
              </div>
            ))}
          </div>
          <div className={styles.distinctionSelectorRight}>
            <img
              src={currentDistinction.image}
              alt={currentDistinction.name}
            ></img>
            <div className={styles.distinctionContent}>
              <h2 className={styles.distinctionHeader}>
                {currentDistinction.name}
              </h2>
              {currentDistinction.body.map((bodySection) => (
                <p className={styles.distinctionBody} key={bodySection}>
                  {bodySection}
                </p>
              ))}
            </div>
          </div>
        </div>
        <div className={styles.mobileDistinctionSelector}>
          <h2>The GHIF Distinction</h2>
          <Accordion
            defaultActiveKey={0}
            className={styles.mobileDistinctionAccordion}
          >
            {distinctions.map((distinction) => (
              <Accordion.Item
                eventKey={distinction.id}
                className={styles.mobileAccordionItem}
                key={distinction.key}
              >
                <Accordion.Header className={styles.mobileAccordionHeader}>
                  {distinction.name}
                </Accordion.Header>
                <Accordion.Body className={styles.mobileAccordionBody}>
                  <div className={styles.mobileAccordionImageWrapper}>
                    <img src={distinction.image} alt='' />
                  </div>
                  <p className={styles.name}>{distinction.name}</p>
                  {distinction.body.map((bodySection) => (
                    <p key={bodySection}>{bodySection}</p>
                  ))}
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
      </Row>
    </Container>
  );
};

export default GhifDistinction;
